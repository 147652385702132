import { createContext, createRef, useContext, useRef } from 'react';
import Notification from '../components/general/Notification';
import { NotificationContextType, NotificationRefType } from '../react-app-env';

export const NotificationContext = createContext<NotificationContextType>({
  ref: createRef<NotificationRefType>(),
});

export const NotificationProvider: React.FC = (props) => {
  const notificationRef = useRef<NotificationRefType>(null);

  return (
    <NotificationContext.Provider value={{ ref: notificationRef }}>
      <Notification ref={notificationRef} />
      {props.children}
    </NotificationContext.Provider>
  );
};

const useNotification = () => useContext(NotificationContext);

export default useNotification;
