import React, { lazy, Suspense } from 'react';
import { PathRouteProps, Route, Routes, useLocation } from 'react-router';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import './routes.css';

const Welcome = lazy(
  () => import(/* webpackChunkName: "welcome" */ './screens/Welcome'),
);
const SignUp = lazy(
  () => import(/* webpackChunkName: "signup" */ './screens/Signup'),
);
const Login = lazy(
  () => import(/* webpackChunkName: "login" */ './screens/Login'),
);
const AdvicePrivacy = lazy(
  () =>
    import(/* webpackChunkName: "advice-privacy" */ './screens/AdvicePrivacy'),
);

const routesConfig: PathRouteProps[] = [
  { path: 'welcome', element: <Welcome />, index: true as any },
  { path: 'signup', element: <SignUp /> },
  { path: 'login', element: <Login /> },
  { path: 'advice-privacy', element: <AdvicePrivacy /> },
  { path: '*', element: <Welcome /> },
];

const AppRoutes: React.FC = () => {
  const location = useLocation();

  return (
    <TransitionGroup className="main-transition-wrapper">
      <CSSTransition
        key={location.key}
        timeout={300}
        classNames="page"
        unmountOnExit
      >
        <Routes location={location}>
          {routesConfig.map(({ element, ...route }) => (
            <Route
              key={route.path}
              {...route}
              element={
                <div className="page">
                  <Suspense fallback={<div />}>{element}</Suspense>
                </div>
              }
            />
          ))}
        </Routes>
      </CSSTransition>
    </TransitionGroup>
  );
};

export default AppRoutes;
