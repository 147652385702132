import { DefaultTheme } from 'styled-components';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

export const theme: DefaultTheme = {
  colors: {
    orange: '#f69b8b',
    darkOrange: '#e97755',
    blue: '#00136f',
    success: '#3ABA83',
    error: '#fe7575',
  },
};

const ThemeProvider: React.FC = ({ children }) => (
  <StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>
);

export default ThemeProvider;
